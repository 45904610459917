import axios from 'axios';

type PKCE = {
  code: string;
  pkceVerifier: string;
};

export type Credentials = {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: 'Bearer';
};
export type CredentialsReceiver = (credentials: Credentials) => void;

const hash = async (s: string): Promise<ArrayBuffer> => crypto.subtle.digest('sha-256', new TextEncoder().encode(s));
const hex = (c: Uint8Array): string =>
  Array.from(new Uint8Array(c))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

const nonce = (length: number): Uint8Array => {
  const r = new Uint8Array(length);
  return crypto.getRandomValues(r);
};

const base64UrlEncode = (s: Uint8Array) => {
  return window
    .btoa(String.fromCharCode.apply(null, Array.from(s)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

const base64UrlDecode = (s: string) => {
  return window.atob(s.replace(/_/g, '/').replace(/-/g, '+'));
};

type AuthenticationFlowState = 'unauthenticated' | 'pending' | 'authenticated';

class AuthenticationFlow {
  state: AuthenticationFlowState;
  args: URLSearchParams;
  hashArgs: URLSearchParams;
  canResumePKCEFlow: boolean;
  haveTokensInURLFragment: boolean;
  credentials?: Credentials;
  callback?: CredentialsReceiver;

  constructor() {
    this.state = 'unauthenticated';
    this.args = new URLSearchParams(window.location.search);
    this.hashArgs = new URLSearchParams(
      window.location.hash.replace(/^.+?authentication_result=/, 'authentication_result=')
    );
    this.canResumePKCEFlow = this.args.has('code') && this.args.has('state') && this.args.get('v') === '2';
    this.haveTokensInURLFragment = this.hashArgs.has('authentication_result') && this.args.get('v') === '2';
  }

  async run(callback: CredentialsReceiver) {
    this.callback = callback;
    // eslint-disable-next-line
    switch (this.state) {
      case 'unauthenticated':
        if (this.haveTokensInURLFragment) {
          const { AccessToken, RefreshToken, IdToken, TokenType, ExpiresIn } = JSON.parse(
            this.hashArgs.get('authentication_result') || '{}'
          );
          this.credentials = {
            access_token: AccessToken,
            refresh_token: RefreshToken,
            id_token: IdToken,
            expires_in: ExpiresIn,
            token_type: TokenType,
          };
          this.state = 'authenticated';
          this.callback(this.credentials!);
        } else if (this.canResumePKCEFlow) {
          this.state = 'pending';
          this.credentials = await this.resumePKCEFlow();
          this.state = 'authenticated';
          this.callback(this.credentials!);
        } else {
          this.startPKCEFlow();
        }
        break;
      case 'authenticated':
        this.callback(this.credentials!);
    }
  }

  // eslint-disable-next-line
  async generatePKCEChallenge() {
    const pkceVerifier = hex(nonce(63));
    const pkceChallenge = base64UrlEncode(new Uint8Array(await hash(pkceVerifier)));
    return { pkceChallenge, pkceVerifier };
  }

  // eslint-disable-next-line
  keyForState(s: string): string {
    return `/auth/state/cognito/${s}`;
  }

  async startPKCEFlow() {
    const timestamp = Date.now();
    const expiry = timestamp + 5 * 60 * 1000;
    const state = base64UrlEncode(nonce(128));
    const store = window.sessionStorage;
    const key = this.keyForState(state);
    const { pkceChallenge, pkceVerifier } = await this.generatePKCEChallenge();
    const idp = new URLSearchParams(window.location.search).get('idp');
    store.setItem(
      key,
      JSON.stringify({
        state,
        pkceChallenge,
        pkceVerifier,
        timestamp,
        expiry,
        authorizationServer: 'cognito',
      })
    );
    const query = new URLSearchParams({
      client: 'ssoCheck',
      state,
      code_challenge: pkceChallenge,
    });
    // eslint-disable-next-line
    idp && query.append('idp', idp);

    window.location.href = `https://signon.benefitsapi.com/?${query.toString()}`;
  }

  // eslint-disable-next-line
  async obtainTokenFromPKCEFlow({ code, pkceVerifier }: PKCE) {
    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: '6l7jeu4r44kgndgeab4aot355m',
      code,
      code_verifier: pkceVerifier,
      redirect_uri: 'https://sso-check.benefitsapi.com/?v=2',
    });
    const response = await axios.post(`https://cognito.benefitsapi.com/oauth2/token`, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  }

  async resumePKCEFlow() {
    const store = window.sessionStorage;
    const code = this.args.get('code');
    const state = this.args.get('state');
    window.history.replaceState({}, '', window.location.pathname);
    if (!code || !state) {
      window.location.href = window.location.origin;
      return;
    }
    const key = this.keyForState(state);
    const session = store.getItem(key);
    if (!session) {
      window.location.href = window.location.origin;
      return;
    }
    store.removeItem(key);
    const { pkceVerifier, authorizationServer, expiry } = JSON.parse(session);
    if (!pkceVerifier || authorizationServer !== 'cognito' || expiry < Date.now()) {
      window.location.href = window.location.origin;
      return;
    }
    // eslint-disable-next-line
    return this.obtainTokenFromPKCEFlow({ code, pkceVerifier });
  }

  userInfo(): { [name: string]: unknown } | undefined {
    if (this.state !== 'authenticated') {
      return;
    }
    const [_first, jwtClaims, _rest] = this.credentials!.id_token.split('.');
    const claimsJson = base64UrlDecode(jwtClaims!);
    // eslint-disable-next-line
    return JSON.parse(claimsJson);
  }
}

export const authenticationFlow = new AuthenticationFlow();
