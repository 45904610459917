import Authenticator from './ui/components/Authenticator/Authenticator';
import { authenticationFlow } from './auth/pkce';
import { useCallback, useState } from 'react';

const LoadingScreen = () => (
  <div className="h-screen flex bg-slate-100">
    <div className="m-auto">
      <svg
        className="animate-spin"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: 50, width: 50 }}
      >
        <circle cx="50" cy="50" r="40" fillOpacity={0} strokeOpacity={1} strokeWidth={6} stroke="gray" />
        <path d="M 50 50 L 30 0 L 60 0 Z" fill="rgb(241, 245, 249)" />
      </svg>
    </div>
  </div>
);

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [user, setUser] = useState<any>({});
  const onAuthenticated = useCallback(() => {
    setUser(authenticationFlow.userInfo());
  }, []);

  return (
    <Authenticator loadingComponent={<LoadingScreen />} onAuthenticated={onAuthenticated}>
      <div className="h-screen flex bg-slate-100">
        <div className="m-auto h-3/4 w-7/12">
          <img src="/logo512.png" className="h-16 mx-auto my-4" alt="Benepass logo" />

          <div className="h-3/4 shadow-lg p-6 rounded-lg bg-white flex flex-col space-y-3">
            <p className="text-xl">You have successfully logged in!</p>
            <pre className="text-xs overflow-scroll border flex-grow p-3">{JSON.stringify(user, null, 2)}</pre>
          </div>
        </div>
      </div>
    </Authenticator>
  );
};

export default App;
