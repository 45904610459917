import { useState, useEffect, ReactElement } from 'react';
import { authenticationFlow, Credentials } from '../../../auth/pkce';

type AuthenticatorProps = {
  loadingComponent: ReactElement;
  children: ReactElement | ReactElement[];
  onAuthenticated?: () => void;
};

const Authenticator = ({ loadingComponent, children, onAuthenticated }: AuthenticatorProps) => {
  const [credentials, setCredentials] = useState<Credentials | null>(null);
  const isAuthenticated: boolean = credentials !== null;

  useEffect(() => {
    authenticationFlow.run(setCredentials);
  }, [setCredentials]);

  useEffect(() => {
    if (isAuthenticated && onAuthenticated) {
      onAuthenticated();
    }
  }, [isAuthenticated, onAuthenticated]);

  return isAuthenticated ? <>{children}</> : loadingComponent;
};

export default Authenticator;
